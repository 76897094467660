import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["amount", "total", "revealable", "type"]

  connect() {
    this.computeTotal()
  }

  computeTotal() {
    this.totalTarget.value = this.amountTargets
        .filter(el => el.offsetParent !== null)
        .filter(el => el.value !== "")
        .map(el => parseFloat(el.value))
        .reduce((sum, b) => sum + b, 0)
    this.setRevealableNameIfAmountExcessive()
  }

  setRevealableNameIfAmountExcessive() {
    this.revealableTarget.setAttribute("data-revealable-name", (this.totalTarget.value > 30_000 ? "micro_credit" : "unrevealed"))
    this.typeTarget.dispatchEvent(new Event("change"))
  }
}
